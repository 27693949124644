import { BlockTimestampAPI } from "@alch/ui/assets/logos";
import blocksTimestampApiCardUrl from "@features/products/assets/card/blocks-timestamp-api.png";
import { ProductConfig } from "@features/products/useProductConfigs";
import { pathBuilder } from "@util/paths";
import { AlchemyDocsUrl } from "@util/urls";

export enum NonProductLandingPage {
  PortfolioApi = "portfolio-api",
}

export type NonProductLandingPageConfig = Omit<ProductConfig, "product"> & {
  product: NonProductLandingPage;
};

export const nonProductLandingPageConfigs: NonProductLandingPageConfig[] = [
  {
    product: NonProductLandingPage.PortfolioApi,
    name: "Portfolio APIs",
    description:
      "The Portfolio APIs provide streamlined utilities that could be used to fetch portfolio data",
    ctaLink: pathBuilder.services.landingPage(
      NonProductLandingPage.PortfolioApi,
    ),
    docsLink: AlchemyDocsUrl.PortfolioApis,
    iconUrl: BlockTimestampAPI,
    cardUrl: blocksTimestampApiCardUrl,
    supportedNetworks: [],
  },
];
