import { Product } from "@alch/dx-entities";
import { Apps, Home02, Sandbox, Settings, Wallet01 } from "@alch/ui/icons/16";
import { NonProductLandingPage } from "@features/product-landing/data/non-product-landing-pages";
import useCurrentUser from "@queries/useCurrentUser";
import { Path, pathBuilder } from "@util/paths.ts";
import { AlchemyDocsUrl, Url } from "@util/urls";
import { useMemo } from "react";
import DataIcon from "./custom-icons/data.svg?react";
import NodeIcon from "./custom-icons/node.svg?react";
import RollupIcon from "./custom-icons/rollup.svg?react";
import ToolsIcon from "./custom-icons/tools.svg?react";

interface BaseNavItem {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  isNew?: boolean;
}

export interface InternalNavLink extends BaseNavItem {
  to: string;
  exact?: boolean;
}

export interface ExternalNavLink extends BaseNavItem {
  href?: string;
}

export type NavLink = InternalNavLink | ExternalNavLink;

export interface NavItemGroup {
  title: string;
  icon?: React.ReactNode;
  items: Array<NavLink>;
}

export interface NavSeparator {
  type: "separator";
}

export type NavItem = NavLink | NavItemGroup | NavSeparator;

export const isExternalLink = (item: NavItem): item is ExternalNavLink => {
  return (item as ExternalNavLink).href != null;
};

export const isInternalLink = (item: NavItem): item is InternalNavLink => {
  return (item as InternalNavLink).to != null;
};

export const isGroupItem = (item: NavItem): item is NavItemGroup => {
  return (item as NavItemGroup).items != null;
};

export const isSeparator = (item: NavItem): item is NavSeparator => {
  return (item as NavSeparator).type === "separator";
};

const useNavItems = (): NavItem[] => {
  const { data: user } = useCurrentUser();
  const isBillingAdmin = !!user?.isBillingAdmin;

  return useMemo(() => {
    return [
      {
        label: "Home",
        to: Path.Home,
        exact: true,
        icon: <Home02 />,
      },
      {
        label: "Apps",
        to: Path.Apps,
        icon: <Apps />,
      },
      {
        label: "Chains",
        to: Path.Chains,
        icon: <Sandbox />,
      },
      { type: "separator" as const },
      {
        title: "Wallets",
        icon: <Wallet01 />,
        items: [
          {
            label: "Smart Wallets",
            to: Path.Accounts,
          },
          {
            label: "Gas Manager",
            to: Path.GasManager,
          },
          {
            label: "Portfolio API",
            to: pathBuilder.services.landingPage(
              NonProductLandingPage.PortfolioApi,
            ),
          },
        ],
      },
      {
        label: "Rollups",
        to: Path.Rollups,
        icon: <RollupIcon />,
      },
      {
        title: "Data",
        icon: <DataIcon />,
        items: [
          {
            label: "NFT API",
            to: pathBuilder.services.landingPage(Product.NftApi),
          },
          {
            label: "Token API",
            to: pathBuilder.services.landingPage(Product.TokenApi),
          },
          {
            label: "Transfers API",
            to: pathBuilder.services.landingPage(Product.TransfersApi),
          },
          {
            label: "Prices API",
            to: pathBuilder.services.landingPage(Product.PricesApi),
          },
          {
            label: "Block Timestamp API",
            to: pathBuilder.services.landingPage(Product.BlockTimestampApi),
          },
          {
            label: "Smart Websockets",
            to: pathBuilder.services.landingPage(Product.Websockets),
          },
          {
            label: "Webhooks",
            to: Path.Webhooks,
          },
          {
            label: "Subgraphs",
            href: Path.SubgraphsRedirect,
          },
        ],
      },
      {
        title: "Node",
        icon: <NodeIcon />,
        items: [
          {
            label: "Node API",
            to: pathBuilder.services.landingPage(Product.NodeApi),
          },
          {
            label: "Mempool",
            to: Path.Mempool,
          },
        ],
      },
      {
        title: "Tools",
        icon: <ToolsIcon />,
        items: [
          {
            label: "Request Logs",
            to: Path.Logs,
          },
          {
            label: "Alerts",
            to: Path.ConfigureAlerts,
          },
          {
            label: "Sandbox",
            to: Path.Sandbox,
          },
          {
            label: "SDKs",
            href: AlchemyDocsUrl.AlchemySDK,
          },
          {
            label: "Faucets",
            href: Url.Faucets,
          },
        ],
      },
      {
        title: "Settings",
        icon: <Settings />,
        items: [
          {
            label: "Usage",
            to: Path.Usage,
          },
          ...(isBillingAdmin
            ? [
                {
                  label: "Billing",
                  to: Path.Billing,
                },
              ]
            : []),
          {
            label: "Profile",
            to: Path.Profile,
          },
          {
            label: "Team",
            to: Path.Team,
          },
          ...(isBillingAdmin
            ? [
                {
                  label: "Access Keys",
                  to: Path.AccessKeys,
                },
              ]
            : []),
          {
            label: "Sign Out",
            to: Path.Logout,
          },
        ],
      },
    ];
  }, [isBillingAdmin]);
};

export default useNavItems;
