import { WaffleFlag } from "@alch/dx-entities";
import AppLayout from "@features/app-layout";
import { DataPlatformPageWrapper } from "@features/data-platform";
import GasManagerPageWrapper from "@features/gas-policies/GasManagerPageWrapper";
import { RedirectToSatsuma } from "@features/satsuma/RedirectToSatsuma.tsx";
import useAnalyticsInDash from "@hooks/useAnalyticsInDash";
import { useWaffleFlag } from "@queries/useWaffleFlags";
import {
  BillingPageTab,
  Path,
  pathBuilder,
  RollupDetailPageTab,
  UsagePageTab,
} from "@util/paths";
import { Switch } from "react-router-dom";
import {
  LazyAccountsConfigCreatePage,
  LazyAccountsConfigEditPage,
  LazyAccountsPage,
  LazyAlertsHubPage,
  LazyAppsDetailsMultiNetworkPage,
  LazyAppsPage,
  LazyChainDetailsPage,
  LazyChainsPage,
  LazyChatWeb3RoutesPage,
  LazyComposerPage,
  LazyConfigureAlertsPage,
  LazyCreateAppPage,
  LazyDataPlatformCreatePage,
  LazyDataPlatformListPage,
  LazyDataPlatformPipelinePage,
  LazyDowngradePage,
  LazyGasManagerPage,
  LazyGasPolicyCreatePage,
  LazyGasPolicyEditPage,
  LazyGasPolicyReviewPage,
  LazyGasPumpCreatePage,
  LazyGasPumpEditPage,
  LazyGasPumpGrantApprovePage,
  LazyGasPumpGrantPage,
  LazyGasPumpPage,
  LazyHomePage,
  LazyLogoutPage,
  LazyMempoolPage,
  LazyPaymentSettingsPage,
  LazyProductLandingPage,
  LazyProductsPage,
  LazyRequestLogsPage,
  LazyRollupDeployPage,
  LazyRollupDetailPage,
  LazyRollupsPage,
  LazySettingsPage,
  LazyTestPage,
  LazyTransactionPage,
  LazyUsagePage,
  LazyWebhooksDetailsPage,
  LazyWebhooksPage,
  LazyWebhooksUnifiedCreatePage,
} from "./LazyPages.tsx";
import { Redirect } from "./Redirect";
import { Route } from "./Route.tsx";

const App = () => {
  useAnalyticsInDash();
  const isRaasSelfServeEnabled = useWaffleFlag(WaffleFlag.RaasSelfServe);

  return (
    <AppLayout>
      <Switch>
        <Route path={Path.Home} component={LazyHomePage} exact />
        <Route path={Path.Apps} component={LazyAppsPage} exact />
        <Route path={Path.Services} component={LazyProductsPage} exact />
        <Redirect path="/products" to={Path.Services} exact />

        <Route
          path={`${Path.Services}/:product/:tab?`}
          component={LazyProductLandingPage}
          exact
        />
        <Redirect
          path="/products/:product"
          to={`${Path.Services}/:product`}
          exact
        />
        <Route path={Path.CreateApp} component={LazyCreateAppPage} exact />
        <Route
          path={`${Path.Apps}/:appId(\\w+)/:tab(setup|metrics|networks|settings)?`}
          component={LazyAppsDetailsMultiNetworkPage}
          exact
        />
        <Route path={Path.Rollups} component={LazyRollupsPage} exact />
        <Route
          path={Path.RollupsDeploy}
          component={isRaasSelfServeEnabled ? LazyRollupDeployPage : undefined}
          exact
        />
        <Route
          path={`${Path.Rollups}/:raasId/:tab(${RollupDetailPageTab.Config})`}
          component={LazyRollupDetailPage}
          exact
        />
        <Redirect
          path={`${Path.Rollups}/:raasId`}
          to={`${Path.Rollups}/:raasId/${RollupDetailPageTab.Config}`}
          exact
        />
        <Route path={Path.ChatWeb3} component={LazyChatWeb3RoutesPage} />
        <Route path={Path.GasManager} component={GasManagerComponent} />
        <Route path={Path.DataPlatform} component={DataPlatformComponent} />
        <Redirect path="/gasManager" to={Path.GasManager} />
        <Route path={Path.Accounts} component={AccountsComponent} />
        <Route path={Path.Logout} component={LazyLogoutPage} exact />
        <Route path={Path.Logs} component={LazyRequestLogsPage} exact />
        <Route
          path={`${Path.Logs}/:appId(\\w+)`}
          component={LazyRequestLogsPage}
          exact
        />
        <Redirect path="/(search|explorer)" to={Path.Logs} exact />
        <Route path={Path.Sandbox} component={LazyComposerPage} exact />
        <Redirect
          path="/(composer|api-explorer)"
          to={{ pathname: Path.Sandbox, search: location.search }}
          exact
        />
        <Route
          path={`${Path.Billing}/:tab(${BillingPageTab.ManagePlan}|${BillingPageTab.Invoices}|${BillingPageTab.PaymentDetails})?`}
          component={LazyPaymentSettingsPage}
          exact
        />
        <Route
          path={`${Path.Billing}/downgrade`}
          component={LazyDowngradePage}
          exact
        />
        <Route
          path={`${Path.ConfigureAlerts}/:alertId?`}
          component={LazyConfigureAlertsPage}
          exact
        />
        <Route path={Path.AlertsHub} component={LazyAlertsHubPage} exact />
        <Route
          path={`${Path.Settings}/:tab`}
          component={LazySettingsPage}
          exact
        />
        <Route path={Path.Mempool} component={LazyMempoolPage} exact />
        <Route
          path={`${Path.Mempool}/:networkPrefix/tx/:txId`}
          component={LazyTransactionPage}
          exact
        />
        <Redirect path="/mempool" to={Path.Mempool} exact />
        <Route path={Path.Webhooks} component={LazyWebhooksPage} exact />
        <Route
          path={Path.WebhooksCreate}
          component={LazyWebhooksUnifiedCreatePage}
          exact
        />
        {/* This one must be after webhooks/create */}
        <Route
          path={pathBuilder.webhooks.details(":id")}
          component={LazyWebhooksDetailsPage}
          exact
        />
        <Redirect path="/notify" to={Path.Webhooks} exact />
        <Route path={Path.Chains} component={LazyChainsPage} exact />
        <Route
          path={`${Path.Chains}/:chainId`}
          component={LazyChainDetailsPage}
          exact
        />
        <Route
          path={Path.SubgraphsRedirect}
          component={RedirectToSatsuma}
          exact
        />
        <Route path="/test" component={LazyTestPage} exact />
        <Route
          path={`${Path.Usage}/:tab(${UsagePageTab.Overview}|${UsagePageTab.Http}|${
            UsagePageTab.WebSockets
          }|${UsagePageTab.Webhooks}|${UsagePageTab.Subgraphs})?`}
          component={LazyUsagePage}
          exact
        />

        <Redirect to={Path.Home} />
      </Switch>
    </AppLayout>
  );
};

function GasManagerComponent() {
  return (
    <GasManagerPageWrapper>
      <Switch>
        <Route path={Path.GasManager} component={LazyGasManagerPage} exact />
        <Route
          path={`${Path.GasManager}/grant/:gasPumpId`}
          component={LazyGasPumpGrantPage}
          exact
        />
        <Route
          path={Path.GasManagerPolicyCreate}
          component={LazyGasPolicyCreatePage}
          exact
        />
        <Route
          path={`${Path.GasManagerPump}/create`}
          component={LazyGasPumpCreatePage}
          exact
        />
        <Route
          path={`${Path.GasManagerPump}/edit/:gasPumpId`}
          component={LazyGasPumpEditPage}
          exact
        />
        <Route
          path={`${Path.GasManagerPump}/:gasPumpId/:policyId/approve`}
          component={LazyGasPumpGrantApprovePage}
          exact
        />
        <Route
          path={`${Path.GasManagerPump}/:gasPumpId`}
          component={LazyGasPumpPage}
          exact
        />
        <Route
          path={`${Path.GasManagerPolicy}/:policyId`}
          component={LazyGasPolicyReviewPage}
          exact
        />
        <Route
          path={`${Path.GasManagerPolicy}/:policyId/edit`}
          component={LazyGasPolicyEditPage}
          exact
        />
        <Redirect to={Path.GasManager} />
      </Switch>
    </GasManagerPageWrapper>
  );
}

function DataPlatformComponent() {
  return (
    <DataPlatformPageWrapper>
      <Switch>
        <Route
          path={Path.DataPlatform}
          component={LazyDataPlatformListPage}
          exact
        />
        <Route
          path={pathBuilder.dataPlatform.pipeline(":id")}
          component={LazyDataPlatformPipelinePage}
          exact
        />
        <Route
          path={Path.DataPlatformCreate}
          component={LazyDataPlatformCreatePage}
          exact
        />
        <Redirect to={Path.DataPlatform} />
      </Switch>
    </DataPlatformPageWrapper>
  );
}

function AccountsComponent() {
  return (
    <Switch>
      <Route path={Path.Accounts} component={LazyAccountsPage} exact />
      <Route
        path={Path.AccountsConfigCreate}
        component={LazyAccountsConfigCreatePage}
        exact
      />
      <Route
        path={`${Path.Accounts}/config/:configId/edit`}
        component={LazyAccountsConfigEditPage}
        exact
      />
      <Redirect to={Path.Accounts} />
    </Switch>
  );
}

export default App;
